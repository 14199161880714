import {
    HIDE_MENU,
    SHOW_MENU,
    TOGGLE_MENU,
    CHANGE_LANGUAGE,
    SET_NOTIFICATION,
    DELETE_NOTIFICATION,
    FETCH_USER_DATA_REQUEST,
    FETCH_USER_DATA_SUCCESS,
    FETCH_USER_DATA_FAILURE,
    FETCH_MENU_PRODUCTS_REQUEST,
    FETCH_MENU_PRODUCTS_SUCCESS,
    FETCH_MENU_PRODUCTS_FAILURE,
    CHANGE_VAT,
    CHANGE_DISCOUNT,
    FETCH_VENDORS_REQUEST,
    FETCH_VENDORS_SUCCESS,
    FETCH_VENDORS_FAILURE,
    FETCH_PRODUCTS_REQUEST,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS_FAILURE,
    FETCH_PUBLIC_PRODUCTS_REQUEST,
    FETCH_PUBLIC_PRODUCTS_SUCCESS,
    FETCH_PUBLIC_PRODUCTS_FAILURE,
    SET_SELECTED_VENDORS,
    SET_ONLY_IN_STOCK,
    SET_SELECTED_ITEM_CATEGORY,
    SET_SELECTED_ITEM_GROUP,
    SET_SELECTED_ITEM_MODEL,
    UPDATE_PRICES_REQUEST,
    UPDATE_PRICES_SUCCESS,
    UPDATE_PRICES_FAILURE,
    SET_SEARCH_VISIBLE,
    SET_SEARCH_QUERY,
    SET_SEARCH_TYPE,
    CHANGE_COUNTRY_REQUEST,
    CHANGE_COUNTRY_SUCCESS,
    CHANGE_COUNTRY_FAILURE,
    ADD_CART,
    FETCH_OEM_VEHICLES_REQUEST,
    FETCH_OEM_VEHICLES_SUCCESS,
    FETCH_OEM_VEHICLES_FAILURE,
    FETCH_OEM_VEHICLES_DETAILS_REQUEST,
    FETCH_OEM_VEHICLES_DETAILS_SUCCESS,
    FETCH_OEM_VEHICLES_DETAILS_FAILURE,
    FETCH_PRODUCT_DETAILS_REQUEST,
    FETCH_PRODUCT_DETAILS_SUCCESS,
    FETCH_PRODUCT_DETAILS_FAILURE,
    CLEAR_PRODUCT_DETAILS,
    FETCH_PRODUCT_DETAILS_VEHICLES_REQUEST,
    FETCH_PRODUCT_DETAILS_VEHICLES_SUCCESS,
    FETCH_PRODUCT_DETAILS_VEHICLES_FAILURE,
    FETCH_CUSTOMER_SEARCH_LIST_REQUEST,
    FETCH_CUSTOMER_SEARCH_LIST_SUCCESS,
    FETCH_CUSTOMER_SEARCH_LIST_FAILURE,
    FETCH_ORDER_HISTORY_LIST_REQUEST,
    FETCH_ORDER_HISTORY_LIST_SUCCESS,
    FETCH_ORDER_HISTORY_LIST_FAILURE,
    FETCH_ORDER_HISTORY_ITEM_REQUEST,
    FETCH_ORDER_HISTORY_ITEM_SUCCESS,
    FETCH_ORDER_HISTORY_ITEM_FAILURE,
    FETCH_TOP_PRODUCTS_REQUEST,
    FETCH_TOP_PRODUCTS_SUCCESS,
    FETCH_TOP_PRODUCTS_FAILURE,
    SET_IMPERSONATED_COSTUMER_REQUEST,
    SET_IMPERSONATED_COSTUMER_SUCCESS,
    SET_IMPERSONATED_COSTUMER_FAILURE,
    CLEAR_IMPERSONATED_COSTUMER_SUCCESS,
    UPDATE_PRICES_DETAILS_REQUEST,
    UPDATE_PRICES_DETAILS_SUCCESS,
    UPDATE_PRICES_DETAILS_FAILURE,
    UPDATE_PRICES_DETAILS_FILTERS,
    SET_SEARCH_RESET_PAGE,
    FETCH_BY_CODE_SEARCH_LIST_REQUEST,
    FETCH_BY_CODE_SEARCH_LIST_SUCCESS,
    FETCH_BY_CODE_SEARCH_LIST_FAILURE,
    CLEAR_BY_CODE_SEARCH_LIST,
    FETCH_FAVOURITES_REQUEST,
    FETCH_FAVOURITES_SUCCESS,
    FETCH_FAVOURITES_FAILURE,
    SET_FAVOURITE_REQUEST,
    SET_FAVOURITE_SUCCESS,
    SET_FAVOURITE_FAILURE,
    REMOVE_FAVOURITE_REQUEST,
    REMOVE_FAVOURITE_SUCCESS,
    REMOVE_FAVOURITE_FAILURE,
    FETCH_AVAILABILITY_NOTIFICATIONS_REQUEST,
    FETCH_AVAILABILITY_NOTIFICATIONS_SUCCESS,
    FETCH_AVAILABILITY_NOTIFICATIONS_FAILURE,
    SET_AVAILABILITY_NOTIFICATIONS_REQUEST,
    SET_AVAILABILITY_NOTIFICATIONS_SUCCESS,
    SET_AVAILABILITY_NOTIFICATIONS_FAILURE,
    REMOVE_AVAILABILITY_NOTIFICATIONS_REQUEST,
    REMOVE_AVAILABILITY_NOTIFICATIONS_SUCCESS,
    REMOVE_AVAILABILITY_NOTIFICATIONS_FAILURE,
    FETCH_TAGS_REQUEST,
    FETCH_TAGS_SUCCESS,
    FETCH_TAGS_FAILURE,
    ADD_TAG_REQUEST,
    ADD_TAG_SUCCESS,
    ADD_TAG_FAILURE,
    DELETE_TAG_REQUEST,
    DELETE_TAG_SUCCESS,
    DELETE_TAG_FAILURE,
    FETCH_TAG_ITEMS_REQUEST,
    FETCH_TAG_ITEMS_SUCCESS,
    FETCH_TAG_ITEMS_FAILURE,
    SET_TAG_ITEM_REQUEST,
    SET_TAG_ITEM_SUCCESS,
    SET_TAG_ITEM_FAILURE,
    REMOVE_TAG_ITEM_REQUEST,
    REMOVE_TAG_ITEM_SUCCESS,
    REMOVE_TAG_ITEM_FAILURE,
    SET_COMMENT_REQUEST,
    SET_COMMENT_SUCCESS,
    SET_COMMENT_FAILURE,
    REMOVE_COMMENT_REQUEST,
    REMOVE_COMMENT_SUCCESS,
    REMOVE_COMMENT_FAILURE,
    FETCH_YQ_CATALOG_REQUEST,
    FETCH_YQ_CATALOG_SUCCESS,
    FETCH_YQ_CATALOG_FAILURE,
    FETCH_YQ_PARAMS_REQUEST,
    FETCH_YQ_PARAMS_SUCCESS,
    FETCH_YQ_PARAMS_FAILURE,
    FETCH_YQ_LIST_VEHICLES_REQUEST,
    FETCH_YQ_LIST_VEHICLES_SUCCESS,
    FETCH_YQ_LIST_VEHICLES_FAILURE,
    FETCH_YQ_FIND_VEHICLE_REQUEST,
    FETCH_YQ_FIND_VEHICLE_SUCCESS,
    FETCH_YQ_FIND_VEHICLE_FAILURE,
    FETCH_YQ_OEM_VEHICLES_REQUEST,
    FETCH_YQ_OEM_VEHICLES_SUCCESS,
    FETCH_YQ_OEM_VEHICLES_FAILURE,
    FETCH_PLATE_VEHICLES_REQUEST,
    FETCH_PLATE_VEHICLES_SUCCESS,
    FETCH_PLATE_VEHICLES_FAILURE,
    SET_SELECTED_TAG,
    FETCH_NEW_PRODUCTS_REQUEST,
    FETCH_NEW_PRODUCTS_SUCCESS,
    FETCH_NEW_PRODUCTS_FAILURE,
    SHOW_NEW_PRODUCTS,
    FETCH_INVOICES_REQUEST,
    FETCH_INVOICES_SUCCESS,
    FETCH_INVOICES_FAILURE,
    UPDATE_FORM_SUBMISSION_STATUS,
    FETCH_ITEM_RETURN_LIST_REQUEST,
    FETCH_ITEM_RETURN_LIST_SUCCESS,
    FETCH_ITEM_RETURN_LIST_FAILURE,
    FETCH_ITEM_RETURN_ITEM_REQUEST,
    FETCH_ITEM_RETURN_ITEM_SUCCESS,
    FETCH_ITEM_RETURN_ITEM_FAILURE,
    ITEM_RETURN_REQUEST,
    ITEM_RETURN_SUCCESS,
    ITEM_RETURN_FAILURE,
    FETCH_RETURN_FORM_DATA_REQUEST,
    FETCH_RETURN_FORM_DATA_SUCCESS,
    FETCH_RETURN_FORM_DATA_FAILURE
} from "../actionTypes/global";


export const toggleMenu = () => ({
    type: TOGGLE_MENU,
});

export const showMenu = () => ({
    type: SHOW_MENU,
});

export const hideMenu = () => ({
    type: HIDE_MENU,
});

export const changeLanguage = (lang) => ({
    type: CHANGE_LANGUAGE,
    payload: lang,
});

export const setSearchVisible = (searchVisible) => ({
    type: SET_SEARCH_VISIBLE,
    payload: searchVisible,
});

export const setSearchQuery = (searchQuery) => ({
    type: SET_SEARCH_QUERY,
    payload: searchQuery,
});

export const setSearchType = (searchType) => ({
    type: SET_SEARCH_TYPE,
    payload: searchType,
});

export const changeVAT = (withVAT) => ({
    type: CHANGE_VAT,
    payload: withVAT,
});

export const addCart = (cartName) => ({
    type: ADD_CART,
    payload: cartName,
});

export const changeDiscount = (withDiscount) => ({
    type: CHANGE_DISCOUNT,
    payload: withDiscount,
});

export const setSelectedVendors = (selectedVendors) => ({
    type: SET_SELECTED_VENDORS,
    payload: selectedVendors,
});

export const setOnlyInStock = (onlyInStock) => ({
    type: SET_ONLY_IN_STOCK,
    payload: onlyInStock,
});

export const setSelectedItemCategory = (itemCategory) => ({
    type: SET_SELECTED_ITEM_CATEGORY,
    payload: itemCategory,
});

export const setSelectedItemGroup = (itemGroup) => ({
    type: SET_SELECTED_ITEM_GROUP,
    payload: itemGroup,
});

export const setSelectedItemModel = (itemModel) => ({
    type: SET_SELECTED_ITEM_MODEL,
    payload: itemModel,
});

export const setSelectedTag = (tag) => ({
    type: SET_SELECTED_TAG,
    payload: tag,
});

export const setNotification = (message) => ({
    type: SET_NOTIFICATION,
    payload: message,
});

export const deleteNotification = () => ({
    type: DELETE_NOTIFICATION,
});

export const fetchUserDataRequest = () => ({
    type: FETCH_USER_DATA_REQUEST,
});

export const fetchUserDataSuccess = (payload) => ({
    type: FETCH_USER_DATA_SUCCESS,
    payload,
});

export const fetchUserDataFailure = (error) => ({
    type: FETCH_USER_DATA_FAILURE,
    error,
});

export const fetchMenuProductsRequest = () => ({
    type: FETCH_MENU_PRODUCTS_REQUEST,
});

export const fetchMenuProductsSuccess = (payload) => ({
    type: FETCH_MENU_PRODUCTS_SUCCESS,
    payload,
});

export const fetchMenuProductsFailure = (error) => ({
    type: FETCH_MENU_PRODUCTS_FAILURE,
    error,
});

export const fetchVendorsRequest = () => ({
    type: FETCH_VENDORS_REQUEST,
});

export const fetchVendorsSuccess = (payload) => ({
    type: FETCH_VENDORS_SUCCESS,
    payload,
});

export const fetchVendorsFailure = (error) => ({
    type: FETCH_VENDORS_FAILURE,
    error,
});

export const fetchProductsRequest = () => ({
    type: FETCH_PRODUCTS_REQUEST,
});

export const fetchProductsSuccess = (payload) => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload,
});

export const fetchProductsFailure = (error) => ({
    type: FETCH_PRODUCTS_FAILURE,
    error,
});

export const fetchPublicProductsRequest = () => ({
    type: FETCH_PUBLIC_PRODUCTS_REQUEST,
});

export const fetchPublicProductsSuccess = (payload) => ({
    type: FETCH_PUBLIC_PRODUCTS_SUCCESS,
    payload,
});

export const fetchPublicProductsFailure = (error) => ({
    type: FETCH_PUBLIC_PRODUCTS_FAILURE,
    error,
});

export const updatePricesRequest = () => ({
    type: UPDATE_PRICES_REQUEST,
});

export const updatePricesSuccess = (payload) => ({
    type: UPDATE_PRICES_SUCCESS,
    payload,
});

export const updatePricesFailure = (error) => ({
    type: UPDATE_PRICES_FAILURE,
    error,
});

export const updatePricesDetailsRequest = (payload) => ({
    type: UPDATE_PRICES_DETAILS_REQUEST,
    payload,
});

export const updatePricesDetailsSuccess = (payload) => ({
    type: UPDATE_PRICES_DETAILS_SUCCESS,
    payload,
});

export const updatePricesDetailsFailure = (payload) => ({
    type: UPDATE_PRICES_DETAILS_FAILURE,
    payload,
});

export const changeCountryRequest = () => ({
    type: CHANGE_COUNTRY_REQUEST,
});

export const changeCountrySuccess = (payload) => ({
    type: CHANGE_COUNTRY_SUCCESS,
    payload,
});

export const changeCountryFailure = (error) => ({
    type: CHANGE_COUNTRY_FAILURE,
    error,
});

export const fetchProductDetailsRequest = () => ({
    type: FETCH_PRODUCT_DETAILS_REQUEST,
});

export const fetchProductDetailsSuccess = (payload) => ({
    type: FETCH_PRODUCT_DETAILS_SUCCESS,
    payload,
});

export const fetchProductDetailsFailure = (error) => ({
    type: FETCH_PRODUCT_DETAILS_FAILURE,
    payload: error,
});

export const clearProductDetails = () => ({
    type: CLEAR_PRODUCT_DETAILS,
});

export const fetchProductDetailsVehiclesRequest = () => ({
    type: FETCH_PRODUCT_DETAILS_VEHICLES_REQUEST,
});

export const fetchProductDetailsVehiclesSuccess = (payload) => ({
    type: FETCH_PRODUCT_DETAILS_VEHICLES_SUCCESS,
    payload,
});

export const fetchProductDetailsVehiclesFailure = (payload) => ({
    type: FETCH_PRODUCT_DETAILS_VEHICLES_FAILURE,
    payload,
});

export const updatePricesDetailsFilters = (payload) => ({
    type: UPDATE_PRICES_DETAILS_FILTERS,
    payload,
});

export const fetchOemVehiclesRequest = () => ({
    type: FETCH_OEM_VEHICLES_REQUEST,
});

export const fetchOemVehiclesSuccess = (payload) => ({
    type: FETCH_OEM_VEHICLES_SUCCESS,
    payload,
});

export const fetchOemVehiclesFailure = (payload) => ({
    type: FETCH_OEM_VEHICLES_FAILURE,
    payload,
});

export const fetchOemVehiclesDetailsRequest = () => ({
    type: FETCH_OEM_VEHICLES_DETAILS_REQUEST,
});

export const fetchOemVehiclesDetailsSuccess = (payload) => ({
    type: FETCH_OEM_VEHICLES_DETAILS_SUCCESS,
    payload,
});

export const fetchOemVehiclesDetailsFailure = (payload) => ({
    type: FETCH_OEM_VEHICLES_DETAILS_FAILURE,
    payload,
});

export const fetchCustomerSearchListRequest = () => ({
    type: FETCH_CUSTOMER_SEARCH_LIST_REQUEST,
});

export const fetchCustomerSearchListSuccess = (payload) => ({
    type: FETCH_CUSTOMER_SEARCH_LIST_SUCCESS,
    payload,
});

export const fetchCustomerSearchListFailure = (payload) => ({
    type: FETCH_CUSTOMER_SEARCH_LIST_FAILURE,
    payload,
});

export const setImpersonatedCustomerRequest = () => ({
    type: SET_IMPERSONATED_COSTUMER_REQUEST,
});

export const setImpersonatedCustomerSuccess = (payload) => ({
    type: SET_IMPERSONATED_COSTUMER_SUCCESS,
    payload,
});

export const setImpersonatedCustomerFailure = (payload) => ({
    type: SET_IMPERSONATED_COSTUMER_FAILURE,
    payload,
});

export const clearImpersonatedCustomerSuccess = () => ({
    type: CLEAR_IMPERSONATED_COSTUMER_SUCCESS,
});

export const fetchOrderHistoryListRequest = () => ({
    type: FETCH_ORDER_HISTORY_LIST_REQUEST,
});

export const fetchOrderHistoryListSuccess = (payload) => ({
    type: FETCH_ORDER_HISTORY_LIST_SUCCESS,
    payload,
});

export const fetchOrderHistoryListFailure = (payload) => ({
    type: FETCH_ORDER_HISTORY_LIST_FAILURE,
    payload,
});

export const fetchOrderHistoryItemRequest = () => ({
    type: FETCH_ORDER_HISTORY_ITEM_REQUEST,
});

export const fetchOrderHistoryItemSuccess = (payload) => ({
    type: FETCH_ORDER_HISTORY_ITEM_SUCCESS,
    payload,
});

export const fetchOrderHistoryItemFailure = (payload) => ({
    type: FETCH_ORDER_HISTORY_ITEM_FAILURE,
    payload,
});

export const fetchTopProductsRequest = () => ({
    type: FETCH_TOP_PRODUCTS_REQUEST,
});

export const fetchTopProductsSuccess = (payload) => ({
    type: FETCH_TOP_PRODUCTS_SUCCESS,
    payload,
});

export const fetchTopProductsFailure = (payload) => ({
    type: FETCH_TOP_PRODUCTS_FAILURE,
    payload,
});

export const setSearchResetPage = (payload) => ({
    type: SET_SEARCH_RESET_PAGE,
    payload,
});

export const fetchByCodeSearchListRequest = () => ({
    type: FETCH_BY_CODE_SEARCH_LIST_REQUEST,
});

export const fetchByCodeSearchListSuccess = (payload) => ({
    type: FETCH_BY_CODE_SEARCH_LIST_SUCCESS,
    payload,
});

export const fetchByCodeSearchListFailure = (error) => ({
    type: FETCH_BY_CODE_SEARCH_LIST_FAILURE,
    error,
});

export const clearByCodeSearchList = () => ({
    type: CLEAR_BY_CODE_SEARCH_LIST,
});

export const fetchFavouritesRequest = () => ({
    type: FETCH_FAVOURITES_REQUEST,
});

export const fetchFavouritesSuccess = (payload) => ({
    type: FETCH_FAVOURITES_SUCCESS,
    payload,
});

export const fetchFavouritesFailure = (payload) => ({
    type: FETCH_FAVOURITES_FAILURE,
    payload,
});

export const setFavoriteRequest = () => ({
    type: SET_FAVOURITE_REQUEST,
});

export const setFavoriteSuccess = () => ({
    type: SET_FAVOURITE_SUCCESS,
});

export const setFavoriteFailure = (payload) => ({
    type: SET_FAVOURITE_FAILURE,
    payload,
});

export const removeFavoriteRequest = () => ({
    type: REMOVE_FAVOURITE_REQUEST,
});

export const removeFavoriteSuccess = () => ({
    type: REMOVE_FAVOURITE_SUCCESS,
});

export const removeFavoriteFailure = (payload) => ({
    type: REMOVE_FAVOURITE_FAILURE,
    payload,
});

export const fetchAvailabilityNotificationsRequest = () => ({
    type: FETCH_AVAILABILITY_NOTIFICATIONS_REQUEST,
});

export const fetchAvailabilityNotificationsSuccess = (payload) => ({
    type: FETCH_AVAILABILITY_NOTIFICATIONS_SUCCESS,
    payload,
});

export const fetchAvailabilityNotificationsFailure = (payload) => ({
    type: FETCH_AVAILABILITY_NOTIFICATIONS_FAILURE,
    payload,
});

export const setAvailabilityNotificationsRequest = () => ({
    type: SET_AVAILABILITY_NOTIFICATIONS_REQUEST,
});

export const setAvailabilityNotificationsSuccess = () => ({
    type: SET_AVAILABILITY_NOTIFICATIONS_SUCCESS,
});

export const setAvailabilityNotificationsFailure = (payload) => ({
    type: SET_AVAILABILITY_NOTIFICATIONS_FAILURE,
    payload,
});

export const removeAvailabilityNotificationsRequest = () => ({
    type: REMOVE_AVAILABILITY_NOTIFICATIONS_REQUEST,
});

export const removeAvailabilityNotificationsSuccess = () => ({
    type: REMOVE_AVAILABILITY_NOTIFICATIONS_SUCCESS,
});

export const removeAvailabilityNotificationsFailure = (payload) => ({
    type: REMOVE_AVAILABILITY_NOTIFICATIONS_FAILURE,
    payload,
});

export const fetchTagsRequest = () => ({
    type: FETCH_TAGS_REQUEST,
});

export const fetchTagsSuccess = (payload) => ({
    type: FETCH_TAGS_SUCCESS,
    payload,
});

export const fetchTagsFailure = (payload) => ({
    type: FETCH_TAGS_FAILURE,
    payload,
});

export const addTagRequest = () => ({
    type: ADD_TAG_REQUEST,
});

export const addTagSuccess = () => ({
    type: ADD_TAG_SUCCESS,
});

export const addTagFailure = (payload) => ({
    type: ADD_TAG_FAILURE,
    payload,
});

export const deleteTagRequest = () => ({
    type: DELETE_TAG_REQUEST,
});

export const deleteTagSuccess = () => ({
    type: DELETE_TAG_SUCCESS,
});

export const deleteTagFailure = (payload) => ({
    type: DELETE_TAG_FAILURE,
    payload,
});

export const fetchTagItemsRequest = () => ({
    type: FETCH_TAG_ITEMS_REQUEST,
});

export const fetchTagItemsSuccess = (payload) => ({
    type: FETCH_TAG_ITEMS_SUCCESS,
    payload,
});

export const fetchTagItemsFailure = (payload) => ({
    type: FETCH_TAG_ITEMS_FAILURE,
    payload,
});

export const setTagItemRequest = () => ({
    type: SET_TAG_ITEM_REQUEST,
});

export const setTagItemSuccess = () => ({
    type: SET_TAG_ITEM_SUCCESS,
});

export const setTagItemFailure = (payload) => ({
    type: SET_TAG_ITEM_FAILURE,
    payload,
});

export const removeTagItemRequest = () => ({
    type: REMOVE_TAG_ITEM_REQUEST,
});

export const removeTagItemSuccess = () => ({
    type: REMOVE_TAG_ITEM_SUCCESS,
});

export const removeTagItemFailure = (payload) => ({
    type: REMOVE_TAG_ITEM_FAILURE,
    payload,
});

export const setCommentRequest = () => ({
    type: SET_COMMENT_REQUEST,
});

export const setCommentSuccess = () => ({
    type: SET_COMMENT_SUCCESS,
});

export const setCommentFailure = (payload) => ({
    type: SET_COMMENT_FAILURE,
    payload,
});

export const removeCommentRequest = () => ({
    type: REMOVE_COMMENT_REQUEST,
});

export const removeCommentSuccess = () => ({
    type: REMOVE_COMMENT_SUCCESS,
});

export const removeCommentFailure = (payload) => ({
    type: REMOVE_COMMENT_FAILURE,
    payload,
});

export const fetchYqCatalogRequest = () => ({
    type: FETCH_YQ_CATALOG_REQUEST,
});

export const fetchYqCatalogSuccess = (payload) => ({
    type: FETCH_YQ_CATALOG_SUCCESS,
    payload
});

export const fetchYqCatalogFailure = (payload) => ({
    type: FETCH_YQ_CATALOG_FAILURE,
    payload,
});

export const fetchYqParamsRequest = () => ({
    type: FETCH_YQ_PARAMS_REQUEST,
});

export const fetchYqParamsSuccess = (payload) => ({
    type: FETCH_YQ_PARAMS_SUCCESS,
    payload
});

export const fetchYqParamsFailure = (payload) => ({
    type: FETCH_YQ_PARAMS_FAILURE,
    payload,
});

export const fetchYqListVehiclesRequest = () => ({
    type: FETCH_YQ_LIST_VEHICLES_REQUEST,
});

export const fetchYqListVehiclesSuccess = (payload) => ({
    type: FETCH_YQ_LIST_VEHICLES_SUCCESS,
    payload
});

export const fetchYqListVehiclesFailure = (payload) => ({
    type: FETCH_YQ_LIST_VEHICLES_FAILURE,
    payload,
});

export const fetchYqFindVehicleRequest = () => ({
    type: FETCH_YQ_FIND_VEHICLE_REQUEST,
});

export const fetchYqFindVehicleSuccess = (payload) => ({
    type: FETCH_YQ_FIND_VEHICLE_SUCCESS,
    payload
});

export const fetchYqFindVehicleFailure = (payload) => ({
    type: FETCH_YQ_FIND_VEHICLE_FAILURE,
    payload,
});

export const fetchYqOemVehicleRequest = () => ({
    type: FETCH_YQ_OEM_VEHICLES_REQUEST,
});

export const fetchYqOemVehicleSuccess = (payload) => ({
    type: FETCH_YQ_OEM_VEHICLES_SUCCESS,
    payload
});

export const fetchYqOemVehicleFailure = (payload) => ({
    type: FETCH_YQ_OEM_VEHICLES_FAILURE,
    payload,
});

export const fetchNewProductsRequest = () => ({
    type: FETCH_NEW_PRODUCTS_REQUEST,
});

export const fetchNewProductsSuccess = (payload) => ({
    type: FETCH_NEW_PRODUCTS_SUCCESS,
    payload
});

export const fetchNewProductsFailure = (payload) => ({
    type: FETCH_NEW_PRODUCTS_FAILURE,
    payload,
});

export const showNewProducts = (payload) => ({
    type: SHOW_NEW_PRODUCTS,
    payload,
});

export const fetchPlateVehiclesRequest = () => ({
    type: FETCH_PLATE_VEHICLES_REQUEST,
});

export const fetchPlateVehiclesSuccess = (payload) => ({
    type: FETCH_PLATE_VEHICLES_SUCCESS,
    payload
});

export const fetchPlateVehiclesFailure = (payload) => ({
    type: FETCH_PLATE_VEHICLES_FAILURE,
    payload,
});

export const fetchInvoicesRequest = () => ({
    type: FETCH_INVOICES_REQUEST,
});

export const fetchInvoicesSuccess = (payload) => ({
    type: FETCH_INVOICES_SUCCESS,
    payload
});

export const fetchInvoicesFailure = (payload) => ({
    type: FETCH_INVOICES_FAILURE,
    payload,
});

export const updateFormSubmissionStatus = (isSubmitted) => ({
    type: UPDATE_FORM_SUBMISSION_STATUS,
    payload: isSubmitted,
});

export const fetchItemReturnListRequest = () => ({
    type: FETCH_ITEM_RETURN_LIST_REQUEST,
});

export const fetchItemReturnListSuccess = (payload) => ({
    type: FETCH_ITEM_RETURN_LIST_SUCCESS,
    payload,
});

export const fetchItemReturnListFailure = (payload) => ({
    type: FETCH_ITEM_RETURN_LIST_FAILURE,
    payload,
});

export const fetchItemReturnItemRequest = () => ({
    type: FETCH_ITEM_RETURN_ITEM_REQUEST,
});

export const fetchItemReturnItemSuccess = (payload) => ({
    type: FETCH_ITEM_RETURN_ITEM_SUCCESS,
    payload,
});

export const fetchItemReturnItemFailure = (payload) => ({
    type: FETCH_ITEM_RETURN_ITEM_FAILURE,
    payload,
});

export const itemReturnRequest = () => ({
    type: ITEM_RETURN_REQUEST,
});

export const itemReturnSuccess = (data) => ({
    type: ITEM_RETURN_SUCCESS,
    payload: data,
});

export const itemReturnFailure = (error) => ({
    type: ITEM_RETURN_FAILURE,
    error,
});

export const fetchReturnFormDataRequest = () => ({
    type: FETCH_RETURN_FORM_DATA_REQUEST,
});

export const fetchReturnFormDataSuccess = (payload) => ({
    type: FETCH_RETURN_FORM_DATA_SUCCESS,
    payload,
});

export const fetchReturnFormDataFailure = (payload) => ({
    type: FETCH_RETURN_FORM_DATA_FAILURE,
    payload,
});