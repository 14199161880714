import React from "react";
import Header from "../../components/Header";
import Wrapper from "../../components/Wrapper";
import { ReturnForm } from "./components/ReturnItemForm";
import { ReturnInstructions } from "./components/ReturnInstructions";

export const ReturnItemForm = () => {
    return (
        <div>
            <Header />
            <Wrapper column={true}>
                <ReturnInstructions />
                <ReturnForm />
            </Wrapper>
        </div>
    );
};