import React, { memo, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../../../core/styles/theme";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Item from "../../../components/Listing/item";
import { Title, Wrapper, Subtitle } from "../../../components/SideListening/style";
import { ProductListHeader, ProductListHeaderItem } from "../../../components/Listing/style";
import { COUNTRY_CURRENCY } from "../../../core/utils";
import {
    SelectFormControl,
    FormLabel,
    StyledBox,
} from '../style'
import { fetchInfoBasketById, orderInfoRequest, orderSubmitRequest } from "../../../core/redux/asyncActions/carts";
import { MultipleAutocomplete } from "../../../components/MultipleAutocomplete";
import { CartInfoSection, CartInfoText, CartInfoSectionLineOrder } from "../../../components/Header/Carts/style";
import { Input } from "../../../components/Form";
import { OrderSummary } from "./OrderSummary";
import { ItemDetailsModal } from "../../../components/Listing/components/ItemDetailsModal";
import { fetchProductDetails } from "../../../core/redux/asyncActions/global";
import TourPopup from "../../../components/Tour/TourPopup";
import Button from "../../../components/Button";
import { setSourceLocation } from "../../../core/redux/actions/carts";
import { StyledCircularProgress } from "../../../components/CircularProgress";


const DELIVERY_TYPE_WAREHOUSE = 1

export const OrderSide = memo(() => {
    const cartContentRef = useRef();
    const [deliveryRef, setDeliveryRef] = useState();
    const commentRef = useRef();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { cartId = "" } = useParams();
    const { scope: currentCountry } = useSelector(
        (state) => state.global.userData
    );
    const { lang } = useSelector((state) => state.global);

    const currentProduct = useSelector(
        (state) => state.global?.currentProduct || null
    );

    const { scope } = useSelector(
        (state) => state.global.userData
    );

    const {
        cartsList = [{}],
        orderInfo = {},
        isLoadingOrderBasket = false,
        isLoadingSubmitOrder,
        errorSubmitOrder
    } = useSelector((state) => state?.carts || {})

    const {
        delivery_type_choices = [],
        source_locations = [],
        delivery_addresses = [],
        destinations_locations = [],
        source_default_location = null,
        destination_default_location = null,
    } = orderInfo || {};

    const {
        items = [],
        formatted_total_without_vat = '',
        formatted_vat = '',
        formatted_total = '',
        title = null,
        vat = null
    } = cartsList.find((cart => cart.id === Number(cartId))) || {}

    const deliveryAddress = [...delivery_addresses, { value: t("productsList.deliveryType.deliveryAddress.new"), id: -1 }]
    const defaultSourceLocation = (source_default_location ? source_default_location : (source_locations ? source_locations[0] : null));
    const defaultDestinationlocation = (destination_default_location ? destination_default_location : (destinations_locations ? destinations_locations[0] : null));

    const [deliveryType, setDeliveryType] = useState(null);
    const [automaticDelivery, setAutomaticDelivery] = useState(false);
    const [deliveryAddresses, setDeliveryAddresses] = useState(0);
    const [sourceLocation, changeSourceLocation] = useState(defaultSourceLocation);
    const [destinationLocation, changeDestinationLocation] = useState(defaultDestinationlocation);
    const [orderedItems, setOrderedItems] = useState(null);

    // useEffect(() => {
    // changeSourceLocation(source_default_location);
    //     changeDestinationLocation(defaultDestinationlocation);
    // }, [source_default_location, defaultDestinationlocation]);

    const [{
        name,
        address,
        city,
        postalCode,
        email,
        phone,
        comments
    }, changeNewDeliveryInfo] = useState({
        name: "",
        address: "",
        city: "",
        postalCode: "",
        email: "",
        phone: "",
        comments: ""
    })

    let formInput = [["name", name], ["address", address], ["city", city], ["postalCode", postalCode]];

    const isNewDeliveryInfo = !name || !address || !city || !postalCode

    const isDisabledOrder =
        !deliveryType
        || !deliveryAddresses
        || !sourceLocation
        || (deliveryAddresses && deliveryAddresses === -1 ? isNewDeliveryInfo : false)


    const handleChangeDelivery = (event) => {
        setDeliveryType(+event.target.value);
        if (event.target.value !== DELIVERY_TYPE_WAREHOUSE) {
            setDeliveryAddresses(-1)
        } else {
            setDeliveryAddresses(+event.target.value)
        }
    };

    const handleChangeAddresses = (event) => {
        setDeliveryAddresses(+event.target.value);
    };

    const handleItemPopupOut = (data) => {
        dispatch(fetchProductDetails(data.item_id, 1));
    };

    const handleLocationChange = (e, selectedOptions) => {
        changeSourceLocation(selectedOptions)
        dispatch(setSourceLocation(sourceLocation?.id))
    };

    const handleDestinationLocationChange = (e, selectedOptions) => {
        changeDestinationLocation(selectedOptions);
    }

    useEffect(() => {
        (async () => {
            await dispatch(orderInfoRequest());
        })()
    }, [dispatch, scope, lang])

    useEffect(() => {
        (async () => {
            await dispatch(fetchInfoBasketById(+cartId, sourceLocation?.id));
            dispatch(setSourceLocation(sourceLocation?.id))
        })()
    }, [dispatch, sourceLocation?.id, items.length, lang, scope])

    const onChangeNewDeliveryInfo = (e) => {
        const { value, name } = e.target
        changeNewDeliveryInfo((prevState => ({
            ...prevState,
            [name]: value
        })))
    }

    const [orderId, setOrderId] = useState(null)
    const orderSubmit = async () => {
        const data = (source_locations.length === 1 || destinations_locations.length === 1) && (await dispatch(orderInfoRequest()));
        const source = data.source_default_location ? (data.source_default_location.id !== sourceLocation.id ? data.source_default_location : sourceLocation) : sourceLocation;

        const destination = data.destination_default_location ? (data.destination_default_location.id !== destinationLocation.id ? data.destination_default_location : destinationLocation) : destinationLocation;
        const isWareHouse = deliveryType === DELIVERY_TYPE_WAREHOUSE;

        const orderId = (((source.id === sourceLocation.id)) || (destination.id === sourceLocation.id)) &&
            await dispatch(orderSubmitRequest(
                +cartId,
                deliveryType,
                isWareHouse,
                source,
                destination,
                deliveryAddresses,
                comments,
                name,
                address,
                city,
                postalCode,
                phone,
                email
            ))
        setOrderId(orderId);
        setOrderedItems(items);
    }

    useEffect(() => {
        setOrderId(null);
        setOrderedItems(null);
    }, [cartId]);

    useEffect(() => {
        for (let i = 0; i < items.length; i++) {
            if (items[i].is_stockmin === true) {
                setAutomaticDelivery(true);
                break;
            }
            setAutomaticDelivery(false);
        }
    }, [cartId, items]);

    let isOrderDisabled = true;
    if (deliveryType === DELIVERY_TYPE_WAREHOUSE) {
        if (sourceLocation && destinationLocation) {
            isOrderDisabled = false;
        }
    } else {
        if (isDisabledOrder) {
            isOrderDisabled = true;
        } else {
            isOrderDisabled = false;
        }
    }
    if (!(items && items.length)) {
        isOrderDisabled = true;
    }

    const deliveryTypeValue = delivery_type_choices.length
        && deliveryType
        && delivery_type_choices.find((item) => item.id === deliveryType).value

    return (
        <Wrapper>
            <Title>{!isLoadingOrderBasket && (title)}</Title>
            {Boolean(orderId) && (
                <Subtitle>{t("productsList.successfulOrder").toUpperCase()}</Subtitle>
            )}
            <div ref={cartContentRef}>
                <ProductListHeader>
                    <ProductListHeaderItem />
                    <ProductListHeaderItem>
                        {t("productsList.tableHeader.AKCode").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem>
                        {t("productsList.tableHeader.oem").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem>
                        {t("productsList.tableHeader.vendor").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem>
                        {t("productsList.tableHeader.product").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem
                        showQuantity={!Boolean(orderId)}>
                        {items[0]?.show_availability && t("productsList.tableHeader.availability").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem
                        showQuantity={!Boolean(orderId)}>
                        {`${t("productsList.tableHeader.price").toUpperCase()}, ${COUNTRY_CURRENCY[currentCountry]
                            }`}
                    </ProductListHeaderItem>
                </ProductListHeader>
                {(isLoadingOrderBasket && source_default_location === null) ? (
                    <StyledBox loading={true}>
                        <StyledCircularProgress disableShrink />
                    </StyledBox>
                ) : (
                    Boolean(!orderId && items && items.length) &&
                    items.map((item) => (
                        <Item
                            showQuantity={!Boolean(orderId)}
                            isAvailability={item.show_availability}
                            data={item}
                            key={item.item_id}
                            handleItemPopupOut={handleItemPopupOut}
                            type="order"
                            orderPage={true}
                        />
                    ))
                )}
                {Boolean(orderId && orderedItems) &&
                    orderedItems.map((item) => (
                        <Item
                            showQuantity={!Boolean(orderId)}
                            lang={lang}
                            isAvailability={item.show_availability}
                            data={item}
                            key={item.item_id}
                            handleItemPopupOut={handleItemPopupOut}
                            type="order"
                            orderPage={true}
                        />
                    ))}
                {currentProduct && (
                    <ItemDetailsModal />
                )}</div>
            <CartInfoSection>
                {automaticDelivery && <FormLabel style={{ color: "#f59d45", marginBottom: "5px" }}>*{t("productsList.cartInfoSection.automaticDelivery")}</FormLabel>}
                <CartInfoSectionLineOrder>
                    <CartInfoText>{t("productsList.cartInfoSection.totalWithoutVAT")}</CartInfoText>
                    <CartInfoText>
                        {formatted_total_without_vat}
                    </CartInfoText>
                </CartInfoSectionLineOrder>
                <CartInfoSectionLineOrder>
                    <CartInfoText>{t("productsList.cartInfoSection.VAT")} ({vat}%):</CartInfoText>
                    <CartInfoText>{formatted_vat}</CartInfoText>
                </CartInfoSectionLineOrder>
                <CartInfoSectionLineOrder>
                    <CartInfoText>
                        <strong>{t("productsList.cartInfoSection.total")}:</strong>
                    </CartInfoText>
                    <CartInfoText>
                        <strong>{formatted_total}</strong>
                    </CartInfoText>
                </CartInfoSectionLineOrder>
            </CartInfoSection>
            {Boolean(orderId) && (
                <OrderSummary
                    deliveryType={deliveryTypeValue}
                    sourceLocation={sourceLocation?.value || sourceLocation?.id}
                    comment={comments}
                />
            )}

            {items && Boolean(items.length) ? (
                <TourPopup
                    delay={500}
                    step={20}
                    anchorEl={cartContentRef.current}
                    placement="bottom-middle"
                >
                    {t('appTour.basketContent')}
                </TourPopup>
            ) : ("")}
            <StyledBox delivery={true} orderId={orderId}>
                {source_locations && source_locations.length > 1 && (
                    <StyledBox>
                        <FormLabel>{t("productsList.sourceLocation")}</FormLabel>
                        <SelectFormControl variant="outlined" size="small">
                            <MultipleAutocomplete
                                multiple={false}
                                options={source_locations}
                                selectedOptions={sourceLocation}
                                onChange={handleLocationChange}
                                placeholder={t("productsList.sourceLocation")}
                            />
                        </SelectFormControl>
                    </StyledBox>
                )}
                {Boolean(delivery_type_choices && delivery_type_choices.length) && (
                    <>
                        <StyledBox ref={ref => setDeliveryRef(ref)}>
                            <FormLabel>{t("productsList.deliveryType.title")}</FormLabel>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="delivery"
                                    value={deliveryType}
                                    onChange={handleChangeDelivery}
                                >
                                    {delivery_type_choices.map(({ value, id }) => (
                                        <FormControlLabel
                                            key={id}
                                            value={id}
                                            control={<Radio
                                                style={{
                                                    color: theme.colors.orange,
                                                    '&.Mui-checked': {
                                                        color: theme.colors.otherOrange,
                                                    },
                                                }} />}
                                            label={value}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </StyledBox>
                        <TourPopup
                            delay={500}
                            step={21}
                            placement={'top'}
                            anchorEl={deliveryRef}
                        >
                            {t('appTour.deliveryMethod')}
                        </TourPopup>
                    </>
                )}
                {Boolean(deliveryType && deliveryType !== DELIVERY_TYPE_WAREHOUSE) && (
                    <>
                        <FormLabel>{t("productsList.deliveryType.deliveryAddress.title")}</FormLabel>
                        <RadioGroup
                            aria-label="delivery"
                            value={deliveryAddresses}
                            onChange={handleChangeAddresses}
                        >
                            {deliveryAddress.map(({ value, id }) => (
                                <FormControlLabel
                                    key={id}
                                    value={id}
                                    control={<Radio
                                        style={{
                                            color: theme.colors.orange,
                                            '&.Mui-checked': {
                                                color: theme.colors.otherOrange,
                                            },
                                        }} />}
                                    label={value}
                                />
                            ))}
                        </RadioGroup>
                    </>
                )}

                {destinations_locations && deliveryType === DELIVERY_TYPE_WAREHOUSE && (
                    <StyledBox>
                        <FormLabel>{t("productsList.deliveryType.pickup")}</FormLabel>
                        {destinations_locations.length > 1 ?
                            <SelectFormControl variant="outlined" size="small">
                                <MultipleAutocomplete
                                    multiple={false}
                                    options={destinations_locations}
                                    selectedOptions={destinationLocation}
                                    onChange={handleDestinationLocationChange}
                                    placeholder={t("productsList.deliveryType.pickup")}
                                />
                            </SelectFormControl>
                            :
                            <Input
                                type="text"
                                name="pickup"
                                placeholder={t("productsList.deliveryType.pickup")}
                                value={destination_default_location.value || destination_default_location.id || destinationLocation}
                                disabled
                            />}
                    </StyledBox>
                )}
                {deliveryAddresses === -1 && deliveryType !== DELIVERY_TYPE_WAREHOUSE && (
                    <>
                        {formInput.map((input) => (
                            <StyledBox>
                                <FormLabel htmlFor={input[0]} required>
                                    {t(`productsList.deliveryType.deliveryAddress.${input[0]}`)}
                                </FormLabel>
                                <Input
                                    type="text"
                                    name={input[0]}
                                    placeholder={t(`productsList.deliveryType.deliveryAddress.${input[0]}`) + "*"}
                                    id={input[0]}
                                    value={input[1]}
                                    onChange={onChangeNewDeliveryInfo}
                                />
                            </StyledBox>
                        ))}
                        <StyledBox>
                            <FormLabel htmlFor="email">
                                {t("productsList.deliveryType.deliveryAddress.email")}
                            </FormLabel>
                            <Input
                                type="text"
                                name="email"
                                placeholder={t("productsList.deliveryType.deliveryAddress.email")}
                                value={email}
                                onChange={onChangeNewDeliveryInfo}
                            />
                        </StyledBox>
                        <StyledBox>
                            <FormLabel htmlFor="phone">
                                {t("productsList.deliveryType.deliveryAddress.phone")}
                            </FormLabel>
                            <Input
                                type="text"
                                inputmode="numeric"
                                name="phone"
                                id="phone"
                                placeholder={t("productsList.deliveryType.deliveryAddress.phone")}
                                value={phone}
                                onChange={onChangeNewDeliveryInfo}
                            />
                        </StyledBox>
                    </>
                )}
            </StyledBox>
            <StyledBox
                orderId={orderId}
                ref={commentRef}
            >
                <FormLabel htmlFor="comments">
                    {t("productsList.commentsTitle")}
                </FormLabel>
                <Input
                    type="text"
                    name="comments"
                    id="comments"
                    placeholder={t("productsList.commentsTitle")}
                    value={comments}
                    onChange={onChangeNewDeliveryInfo}
                />
            </StyledBox>
            <TourPopup
                delay={500}
                step={22}
                anchorEl={commentRef.current}
                placement="top"
                last
            >
                {t('appTour.orderComments')}
            </TourPopup>
            {Boolean(errorSubmitOrder) && <FormLabel>{errorSubmitOrder}</FormLabel>}
            <StyledBox order={true} orderId={orderId}>
                <Button disabled={isOrderDisabled || isLoadingSubmitOrder} color="primary" onClick={orderSubmit}>
                    {isLoadingSubmitOrder ? (
                        <StyledCircularProgress disableShrink />
                    ) : t("productsList.order").toUpperCase()}
                </Button>
            </StyledBox>
        </Wrapper>
    );
});
