import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { FormLabel, OrderList } from "../../Order/style";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { fetchItemReturnItem } from "../../../core/redux/asyncActions/global";
import { ITEM_RETURN_LIST_PATH } from "../../../core/routes";
import { Wrapper, Title, Subtitle } from "../../../components/SideListening/style";
import { BackButton } from "../../OrderHistoryItem/style";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { StyledCircularProgress } from "../../../components/CircularProgress";


export const ReturnItemObj = () => {
    const { t } = useTranslation();
    const { id = "" } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.global.itemReturnItem);
    const [isLoading, setIsLoading] = useState(true)

    const handleReturn = () => {
        if (history.location.pathname !== ITEM_RETURN_LIST_PATH) {
            history.push(ITEM_RETURN_LIST_PATH);
        }
    };    

    const getStatusText = (statusCode) => {
        switch (statusCode) {
            case 0:
                return t("itemReturnList.statuses.received");
            case 1:
                return t("itemReturnList.statuses.inReview");
            case 2:
                return t("itemReturnList.statuses.atTheManufacturer");
            case 3:
                return t("itemReturnList.statuses.responseReceived");
            case 4:
                return t("itemReturnList.statuses.accepted");
            case 5:
                return t("itemReturnList.statuses.rejected");
        }
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchItemReturnItem(id))
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    }, [dispatch, id]);

    const hasResponseData = (data) => {
        return Boolean(data && (data.response_text !== null || Object.keys(data.response_files).length > 0));
    };

    return (
        <Wrapper>
            <BackButton onClick={handleReturn}>
                <BackspaceIcon />
                {t("productsList.orderHistory.return")}
            </BackButton>
            {isLoading ? (
                <Box display="flex" alignItems="center" justifyContent="center" margin="40px">
                    <StyledCircularProgress disableShrink/>
                </Box>
            ) : (
                <OrderList>
                    {
                        data ? (
                            <>
                                <Title>{t("itemReturnList.tableTitles.returnApplication")}: {data.return_application_number && data.return_application_number.toUpperCase()}</Title>
                                <Grid container spacing={1} style={{ marginTop: "20px" }}>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{t("itemReturnItem.status")}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{getStatusText(data.status)}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{t("itemReturnItem.part")}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{data.item_number}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{t("itemReturnItem.docNumber")}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{data.document_number}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{t("itemReturnItem.installDate")}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{data.item_installed_date}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{t("itemReturnItem.removeDate")}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{data.item_removed_date}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{t("itemReturnItem.purchaseDate")}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{data.purchase_date}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{t("itemReturnItem.installOdo")}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{data.item_installed_odometer}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{t("itemReturnItem.removeOdo")}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{data.item_removed_odometer}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{t("itemReturnItem.submitDate")}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{data.form_submitted_date}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{t("itemReturnItem.vehicle")}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{data.vehicle_identification}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{t("itemReturnItem.shopReturned")}</FormLabel>
                                    </Grid>
                                    <Grid item md={2} xs={6}>
                                        <FormLabel>{data.shop_returned}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Box marginTop="20px">
                                    <Subtitle> {t("itemReturnItem.userInfo")} </Subtitle>
                                </Box>
                                <Grid container spacing={1}>
                                    <Grid item xs={2}>
                                        <FormLabel>{t("itemReturnItem.user")}</FormLabel>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <FormLabel>{data.user}</FormLabel>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormLabel>{t("itemReturnItem.email")}</FormLabel>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <FormLabel>{data.email}</FormLabel>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormLabel>{t("itemReturnItem.phone")}</FormLabel>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <FormLabel>{data.phone}</FormLabel>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormLabel>{t("itemReturnItem.registeredOffice")}</FormLabel>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <FormLabel>{data.registered_office}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Box marginTop="20px">
                                    <Subtitle>{t("itemReturnItem.description")}</Subtitle>
                                </Box>
                                <Grid container spacing={1}>
                                    {data.description && (
                                        data.description.includes("\n") ? (
                                            data.description.split("\n").map((paragraph, index) => (
                                                <Grid item xs={12} key={index}>
                                                    <FormLabel>{paragraph}</FormLabel>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Grid item xs={12}>
                                                <FormLabel>{data.description}</FormLabel>
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                                {Object.keys(data.return_application_files).length > 0 && (
                                    <>
                                        <Box marginTop="20px">
                                            <Subtitle>{t("itemReturnItem.attachments")}</Subtitle>
                                        </Box>
                                        <Grid container spacing={1}>
                                            {Object.entries(data.return_application_files).map(([filename, link]) => (
                                                <Grid item xs={12} key={filename}>
                                                    <FormLabel>
                                                        <a href={link} target="_blank" rel="noreferrer" style={{"text-decoration": "none"}}>
                                                            {filename}
                                                        </a>
                                                    </FormLabel>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </>
                                )}
                                {hasResponseData(data) && (
                                    <>
                                        <Title style={{ marginTop: "50px" }}>{t("itemReturnItem.response")}</Title>
                                        <Grid container spacing={1}>
                                            {data.response_text && (
                                                data.response_text.includes("\n") ? (
                                                    data.response_text.split("\n").map((paragraph, index) => (
                                                        <Grid item xs={12} key={index}>
                                                            <FormLabel>{paragraph}</FormLabel>
                                                        </Grid>
                                                    ))
                                                ) : (
                                                    <Grid item xs={12}>
                                                        <FormLabel>{data.response_text}</FormLabel>
                                                    </Grid>
                                                )
                                            )}
                                        </Grid>
                                        {Object.keys(data.response_files).length > 0 && (
                                            <>
                                                <Box marginTop="20px">
                                                    <Subtitle>{t("itemReturnItem.attachments")}</Subtitle>
                                                </Box>
                                                <Grid container spacing={1}>
                                                    {Object.entries(data.response_files).map(([filename, link]) => (
                                                        <Grid item xs={12} key={filename}>
                                                            <FormLabel>
                                                                <a href={link} target="_blank" rel="noreferrer" style={{"text-decoration": "none"}}>
                                                                    {filename}
                                                                </a>
                                                            </FormLabel>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <Box display="flex" alignItems="center" justifyContent="center" margin="40px">
                                <Title>{t("itemReturnItem.error")}</Title>
                            </Box>
                        )
                    }
                </OrderList>
            )}
        </Wrapper>
    );
};