export const fi = {
    translation: {
        "Browse Product Catalog": "BROWSE PRODUCT CATALOG",
        "Show only items on stock": "Show only items on stock",
        "Choose a manufacturer": "Choose a manufacturer",
        "Product Catalog": "Product Catalog",
        email: "Sähköposti",
        submit: "Lähetä",
        login: {
            title: "Kirjaudu sisään",
            language: {
                en: "English",
                lv: "Latviešu",
                lt: "Lietuviškai",
                et: "Eesti",
                fi: "Suomi",
                no: "Norsk (bokmål)",
                ru: "Русский",
                sv: "Svenska",
            },
            clientId: "Käyttäjänimi",
            password: "Salasana",
            login: "Kirjaudu sisään",
            forgotPassword: "Unohtuiko salasana?",
            companyRegistration: "Rekisteröidy",
            tnc: "Terms & conditions",
            privacyPolicy: "Tietosuojakäytäntö",
            rights: "® 1994 - 2021, Auto Kada. Kaikki oikeudet pidätetään",
            errorMessageCredentials:
                "Ole hyvä ja syötä kelvollinen käyttäjätunnus ja salasana. Huomaa että kummassakin kentässä isoilla ja pienillä kirjaimilla saattaa olla merkitystä.",
            errorMessageIP: "This user account is not allowed to log in from this IP address",
            privacyPolicyEndorsement:
                "Napsauttamalla alla olevaa linkkiä hyväksyt meidän",
            privacyPolicyAgreement: "Hyväksyn yksityisyyskäytännön.",
        },
        privacyPolicy: {
            title: "HENKILÖTIETOJEN KÄSITTELY",
            bulletPoints: {
                1: "Osapuolet voivat tämän sopimuksen puitteissa suorittaa henkilötietojen käsittelyä sen mukaan kuin säädetään 27. päivänä huhtikuuta 2016 annetussa Euroopan parlamentin ja neuvoston asetuksessa 2016/679 luonnollisten henkilöiden suojelusta henkilötietojen käsittelyssä sekä näiden tietojen vapaasta liikkuvuudesta.",
                2: "Osapuolet vakuuttavat ja sitoutuvat siihen, että heidän toisiltaan saamiaan tietoja (mukaan lukien tiedot, datan tästä sivustosta, B2B-järjestelmästä) ei saa luovuttaa kolmansille osapuolille ilman toisen osapuolen suostumusta.",
                3: "Osapuolet toteuttavat asianmukaiset tekniset ja organisatoriset toimenpiteet sen varmistamiseksi, että oletuksena käsitellään ainoastaan sellaiset henkilötiedot, joita osapuolten toiminta tämän sopimuksen puitteissa ja kukin konkreettinen käsittelytarkoitus edellyttää. Mainittu velvollisuus koskee kerättyjen ja luovutettujen henkilötietojen määrää, niiden käsittelyastetta, säilytysaikaa ja saatavuutta.",
                4: "Osapuolet varmistavat, valvovat ja kehittävät jatkuvasti toimenpiteitä, joilla suojataan henkilötietoja luvattomalta pääsyltä, tahattomalta kadottamiselta, paljastumiselta tai tuhoamiselta. Osapuolet eivät ota vastuuta mistään luvattomasta pääsystä henkilötietoihin ja/tai henkilötietojen menettämisestä, jos se tapahtuu kyseisestä Osapuolesta riippumattomasta syystä, esimerkiksi toisen Osapuolen tai henkilön syystä ja/tai laiminlyönnistä johtuen.",
                5: "Henkilötietojen tietoturvaloukkauksen sattuessa kyseisen Osapuolen on välittömästi ilmoitettava henkilötietojen tietoturvaloukkauksesta toiselle Osapuolelle sekä, ilman aiheetonta viivytystä, toimivaltaiselle valvontaviranomaiselle (Latvian Valtion tietosuojavirastolle (Datu valsts inspekcija) www.dvi.gov.lv)viimeistään 72 tunnin kuluessa siitä, kun Osapuoli on saanut tiedon tapahtuneesta loukkauksesta, paitsi jos on epätodennäköistä, että henkilötietojen tietoturvaloukkaus voisi vaarantaa luonnollisten henkilöiden oikeuksia ja vapauksia.",
            },
        },
        passwordReset: {
            sendResetLinkStep: {
                text:
                    "Unohtuiko salasana? Kirjoita sähköpostiosoitteesi alle ja lähetämme ohjeet uuden salasanan asettamiseen.",
                errorMsg: "Syötä kelvollinen sähköpostiosoite.",
            },
            resetLinkSuccessfullySentStep: {
                text1:
                    "Lähetimme sinulle ohjeet salasanan uusimiseen, mikäli antamallesi sähköpostille on olemassa tili. Saat viestin pian.",
                text2:
                    "Jos et saa viestiä, varmista, että olet syöttänyt oikean sähköpostiosoitteen ja tarkista roskapostikansio.",
            },
            resetPasswordStep: {
                text:
                    "Ole hyvä ja kirjoita uusi salasana kahdesti, jotta voimme varmistaa oikean kirjoitusasun.",
                newPassword: "Uusi salasana",
                confirmPassword: "Vahvista salasana",
                submit: "Vaihda salasanani",
                errorMsg: "Salasanakentät eivät vastaa toisiaan.",
            },
            invalidResetLinkStep: {
                text:
                    "Salasanan uusimislinkki oli epäkelpo, mahdollisesti koska se on jo käytetty. Ole hyvä ja pyydä uutta salasanan uusintaa.",
            },
            resetPasswordSuccessStep: {
                text: "Salasanasi on uusittu. Voit kirjautua nyt sisään.",
                login: "Kirjaudu sisään",
            },
        },
        breadcrumbs: {
            backTitle: "Takaisin",
        },
        register: {
            title: "REKISTERÖINTI",
            company: {
                subtitle: "YHTIÖ",
                name: "Nimi",
                registrationNumber: "Rekisterinumero",
                vatRegistrationNr: "ALV rekisteri numero",
                address: "Osoite",
                city: "Kaupunki",
                postalCode: "Postinumero",
                country: "Maa",
                chooseCountry: "Valitse maasi",
                email: "Sähköposti",
                phoneNumber: "Puhelinnumero",
            },
            user: {
                subtitle: "KÄYTTÄJÄ",
                usernameInputExplanation:
                    "Vaaditaan enintään 150 merkkiä. Kirjaimet, numerot ja @. + - _ vain.",
                password: "Salasana",
                passwordConfirmation: "Salasanan vahvistus",
                firstName: "Etunimi",
                lastName: "Sukunimi",
                email: "Sähköposti",
            },
            register: "REKISTERÖIDY",
            validationMessages: {
                name:
                    "Nimikentässä on oltava vähintään 2 ja enintään 30 merkkiä. Sen ei tulisi sisältää erikoismerkkejä.",
                surname:
                    "Sukunimikentässä on oltava vähintään 2 ja enintään 30 merkkiä. Sen ei tulisi sisältää erikoismerkkejä.",
                required: "Tämä kenttä pitää täyttää",
                userName: "Anna kelvollinen käyttäjänimi",
                phoneNumber:
                    "Puhelinnumeron tulee olla 11 numeroa, joiden edessä on 0.",
                password:
                    "Salasanasi on oltava enintään 20, vähintään 6 merkkiä.",
                confirmPassword: "Salasanat eivät täsmää",
                tos: "Lue ja vahvista käyttäjän sopimus.",
                email: "Ole hyvä ja syötä toimiva sähköpostiosoite.",
            },
            registrationSuccess:
                "Rekisteröinti on valmis. Otamme sinuun pian yhteyttä.",
        },
        headerUserMenu: {
            impersonateCustomer: "Imitoi asiakas",
            stopImpersonation: "Lopeta teeskentely",
            switchLanguage: "Vaihda kieltä",
            switchCountry: "Vaihda maata",
            orderHistory: "Tilaushistoria",
            itemReturns: "Tuotepalautukset / takuu",
            logout: "Kirjaudu ulos",
        },
        header: {
            searchByCode: "OE/OEM/toimittajan numero",
            searchByVehicle: "VIN-numero",
            searchByPlate: "Rekisterikilpi",
            byCode: "Koodilla",
            byVehicle: "Ajoneuvolla",
            byManufacturer: "Valmistajan mukaan",
            byPlate: "Rekisterikilven mukaan",
            searchHistory: "HAKUHISTORIA",
            setAsDefaultCart: "Aseta oletus ostoskoriksi",
            removeDefaultCart: "Poista oletus ostoskori",
            emptyCart: "Tyhjennä ostoskori",
            deleteCart: "Poista ostoskori",
            confirm: "Vahvistaa",
            cancel: "Peruuttaa",
            emptyCartMessage: "Kärryn tyhjentäminen poistaa kaikki sen tuotteet! Haluatko edetä?",
            deleteCartMessage: "Ostoskori poistetaan ja kaikki sen tuotteet poistetaan! Haluatko edetä?",
            carByParameters: {
                title: "Auton tunnistus parametrien mukaan",
                listVehicles: "Listaa ajoneuvot",
                modifications: "Näytä lisää modeja",
            },
            byVinFrame: {
                title: "VIN/Framen mukaan",
                tip: "Anna Vin tai kehysnumero",
                errorVehicleSearch: "Annetuilla parametreilla varustettua ajoneuvoa ei löytynyt",
            },
            tags: {
                addTag: "Valitse olemassa oleva tunniste tai luo uusi",
                inputTag: "Anna tunniste",
                createTag: "Luo ja lisää tunniste",
            },
            comments: {
                addComment: "Ole hyvä ja lisää kommentti",
                inputComment: "Kirjoita kommentti",
                createComment: "Luo ja lisää kommentti"
            }
        },
        sideMenu: {
            browseProductCatalog: "Tuotekuvastoa",
            newProducts: "Uudet tuotteet",
            onlyItemsOnStock: "Näytä vain varastossa olevat",
            chooseAManufacturer: "Valitse myyjä",
        },
        notification: {
            actionName: {
                added: "on lisätty ostoskoriin",
                removed: "on poistettu ostoskorista",
                addedFav: "on lisätty suosikkeihin",
                removedFav: "on poistettu suosikeista",
                removedAvailNotification: "poistettu saatavuusilmoitusluettelosta",
                addedAvailNotification: "lisätty saatavuusilmoitusluetteloon",
                cartEmptied: "Ostoskori on tyhjennetty:",
                cartDeleted: "Ostoskori on poistettu:",
                cartDefaulted: "Aseta oletuskärryksi:",
                cartUndefaulted: "Oletuskori poistettu:",
                addedTag: "lisätty tunnisteeseen",
                tagDeleted: "Tunniste on poistettu:",
                commentSet: "Kommentti on asetettu",
                commentRemoved: "Kommentti on poistettu",
                commentExists: "Kommentti on jo olemassa"
            },
            itemHasBeen: "Tuote",
        },
        productsList: {
            fetching: "Ladataan lisää tuotteita...",
            vehicle: "Ajoneuvo",
            productCatalog: "Tuotekuvasto",
            withDiscount: "Hinnat alennuksella",
            withVAT: "Hinnat arvonlisäverolla",
            noInfoError: "Järjestelmässä ei ole tietoa",
            order: "Tilaa",
            successfulOrder: "Tilaus on hyväksytty.",
            orderHistory: {
                title: "Tilaushistoria",
                number: "Numero",
                created: "Luotu",
                state: "Tila",
                user: "Käyttäjä",
                customer: "Asiakas",
                total: "Loppusumma",
                sourceLocation: "Lähdesijainti",
                vendorCode: "Myyjän tunnus",
                description: "Kuvaus",
                unitPrice: "Yksikköhinta",
                count: "Määrä",
                return: "Palaa",
                delivery: "Toimitus",
                items: "Tuotteet",
            },
            tableHeader: {
                image: "Kuva",
                product: "Tuote",
                AKCode: "AK-koodi",
                oem: "OEM-koodi",
                vendor: "Myyjä",
                stock: "Varasto",
                availability: "Saatavuus",
                price: "Hinta",
                quantity: "Lisää koriin",
                comment: "Kommentti",
            },
            tableVehiclesHeader: {
                description: "kuvaus",
                model: "mallivuosi",
                build: "rakentaa",
                tonnage: "TONNAUS",
                axle: "akselin kokoonpano",
            },
            stockFlyout: {
                title: "SAATAVUUS VARASTOSSA",
                deliveryInfo: "Mahdollinen\nEXPRESS 24h + EUR",
            },
            favouriteFlyout: {
                add: "Lisää suosikkeihin",
                remove: "Poista suosikeista",
            },
            notificationFlyout: {
                add: "Lisää saatavuusilmoitusluetteloon",
                remove: "Poista saatavuusilmoitusluettelosta",
            },
            commentFlyout: {
                add: "Lisää kommentti",
            },
            tagFlyout: {
                add: "Lisää tageja",
            },
            publicFlyout: {
                text: "Saatavuus, tilaaminen, OE-koodit, haku VIN No. saatavana vain rekisteröityneille käyttäjille.",
                link: "Rekisteröidy täällä",
            },
            cartButton: {
                title: "LISÄÄ OSTOSKORIIN",
                flyout: {
                    title:
                        "Valitse olemassa oleva ostoskori tai luo uusi ostoskori",
                    cartName: "Ostoskorin nimi",
                    btnCaption: "Luo ja lisää tuote",
                    inputPlaceholder: "Anna ostoskorin nimi",
                },
            },
            cartInfoSection: {
                automaticDelivery: "Näitä tuotteita ei tarvitse tilata, koska niillä on automaattinen toimitus",
                quantity: "Määrä: ",
                totalWithoutVAT: "Loppusumma ilman ALV:tä:",
                VAT: "ALV",
                total: "Loppusumma"
            },
            sourceLocation: "Lähdesijainti",
            deliveryType: {
                title: "Toimituksen tyyppi",
                deliveryAddress: {
                    title: "Toimitusosoite",
                    name: "Nimi",
                    address: "Osoite",
                    city: "Kaupunki",
                    postalCode: "Postinumero",
                    email: "Sähköposti",
                    phone: "Puhelinnumero",
                    new: "Uusi",
                },
                pickup: "Hakuosoite",
            },
            commentsTitle: "Kommentit",
            user: "Käyttäjä",
            date: "Päivämäärä",
            detailsModal: {
                tabs: {
                    alternativeProducts: "VAIHTOEHTOISET TUOTTEET",
                    specification: "TEKNISET TIEDOT",
                    oemCodes: "OEM-KOODIT",
                    linkedVehicles: "LIITETYT AJONEUVOT",
                },
            },
        },
        appTour: {
            searchByCode: "B2B suorittaa hakua sekä AUTO KADA:n varastotunnusten että alkuperäisten valmistajanumeroiden perusteella",
            logoButton: "Ensimmäiseen näkymään voit palata käyttämällä AUTO KADA -logonäppäintä tai Takaisin-painiketta",
            grossPrices: "Näytä kaupan bruttohinnat tai asiakkaan hinnat",
            vatPrices: "Näytä arvonlisäverolliset tai arvonlisäverottomat hinnat",
            productCatalog: "Tuotekategoriat – nopea hakujärjestelmä tuoteryhmittäin",
            // searchByVin: "Haku ajoneuvon valmistenumeron (VIN) perusteella -valikko",
            languageMenu: "Kieli, tilaushistoria ja tuotteiden palautus/takuuvalikko",
            returnForm: "Jos sinun tarvitsee palauttaa tuote, täytäthän 'Tuotteen palautus' lomakkeen!",
            returnApplicationsList: "Täältä voit tarkastella kaikkia lähettämiäsi tuotteen palautushakemuksia ja tarkistaa niiden tilat ja vastaukset.",
            shoppingCart: "Ostoskori",
            itemsInStock: "Näytä ainoastaan varastossa olevat tuotteet",
            selectedManufacturer: "Valittu valmistaja",
            notificationsAndFavorites: "Lisää tuote suosikkeihisi napsauttamalla rataskuvaketta.\nLisää tuote ilmoituslistallesi klikkaamalla kellokuvaketta, niin saat ilmoituksen, kun tuotetta on taas varastossa.\nLisättyjen tuotteiden luettelot ovat käytettävissä sivun yläosassa ostoskorikuvakkeen vieressä. Jos jokin ilmoitusluetteloon lisätyistä tuotteista tulee saataville, näet useita saatavilla olevia tuotteita vihreässä ympyrässä ilmoitusluettelokuvakkeen vieressä.",
            favorites: "Lisää tuote suosikkeihisi napsauttamalla rataskuvaketta.\nLuettelo suosikkituotteistasi löytyy sivun yläreunasta ostoskorikuvakkeen vieressä.",
            // manufacturersMenu: "Valmistajan valikko",
            searchParameters: "Valitut hakuparametrit, haku tapahtuu ainoastaan näiden parametrien perusteella myös hakemalla AK- tai OE-koodien perusteella",
            particularProduct: "Tuotteen napsauttaminen avaa ylimääräisen ikkunan, jossa näkyvät kyseiseen numeroon liittyvät konkreettiset tuotteet, tekniset tiedot, kuvat, alkuperäiset numerot sekä mallit, joille kyseinen tuote on tarkoitettu.",
            searchDone: "Haku suoritetaan ajoneuvon täyden valmistenumeron (VIN) syöttämisen jälkeen",
            openDetails: "Kun avaat haetun varaosan ikkunan ja klikkaat sitä, näyttöön tulee AUTO KADA -varastossa olevien tuotteiden valmistajat, määrät ja hinnat.",
            pictureNumbers: "Voit myös suorittaa haun klikkaamalla kiinnostavan varaosan vieressä olevia numeroita.",
            addToCart: "Ohjaa tuote VIN-luettelosta suoraan tilauskoriin.\n P.S. Helpomman työskentely varmistamiseksi voit avata useita eri selainikkunoita suorittamaan hakua useiden ajoneuvon valmistenumeroiden (VIN) perusteella tai samanaikaisesti hakemaan muita AK- tai OE-numeroita.",
            chooseBasket: "Valitse kori tai luo uusi",
            basketContent: "Ostoskorin sisältö, lukumäärä – mahdollisuus muokata hintoja, summia",
            deliveryMethod: "Toimitustapa valitsemalla AUTO KADA -toimituksen, postin – ensimmäisellä kerran on annettava osoite, sen jälkeen osoite on annettava, jos haluat, että tuote toimitetaan toiseen osoitteeseen.",
            orderComments: "Tilauksen suorittamisen yksityiskohtiin liittyvät huomautukset.",
            replyTour: "Toista ohje",
            next: "Seuraava",
            finish: "Finish"
        },
        invoices: {
            availableCreditLimit: "Käytettävissä oleva luottoraja",
            totalCreditLimit: "Koko luottoraja",
            balance: "Saldo",
            latePayments: "Myöhästyneet maksut",
            invoices: "Laskut",
            invoiceDate: "Laskutus päivämäärä",
            invoiceNumber: "Laskun numero",
            totalValue: "Kokonaisarvo",
            remainingValue: "Jäljellä oleva arvo",
            dueDate: "Eräpäivä",
            status: "Tila",
            documentNo: "Dokumentti numero",
            filter: "Suodattaa",
            discardFilters: "Hävitä suodattimet",
            from: "Alkaen",
            to: "Asti",
            paid: "Maksettu",
            unpaid: "Palkaton",
            partiallyPaid: "Osittain maksettu",
            unpaidLate: "Palkaton (myöhässä)",
            partiallyPaidLate: "Osittain maksettu (myöhässä)",
            clear: "Siivota",
            cancel: "Peruuttaa",
            ok: "Okei"
        },
        autocomplete: {
            noOptions: "Ei valintoja",
            open: "Avata",
            close: "Sulkea"
        },
        dataGrid: {
            noResults: "Ei tuloksia"
        },
        itemReturnForm: {
            title: "Tavaroiden palautus",
            serverError: "Tavaran palautuslomake ei ole käytettävissä (palvelinvirhe), yritä myöhemmin uudelleen!",
            submitError: "Palvelinvirhe, yritä uudelleen myöhemmin!",
            submit: "Lähetä",
            formFields: {
                partNumber: "Osanumero",
                enterPartNumber: "Syötä osanumero",
                docNumber: "Lasku/kuittinumero",
                enterDocNumber: "Syötä asiakirjanumero",
                purchaseDate: "Ostamisen päivämäärä",
                legalAddr: "Osoite",
                enterLegalAddr: "Syötä osoite",
                itemInstalledDate: "Osan asennuspäivä",
                itemRemovedDate: "Osan poistopäivä",
                itemInstalledOdo: "Osan asennuksen aikainen matkamittarin lukema (km)",
                enterOdo: "Syötä matkamittarin lukema (km)",
                itemRemovedOdo: "Osan poiston aikainen matkamittarin lukema (km)",
                vehicleIdentity: "Auto: runkonumero/ perävaunu: valmistaja, sillanumero",
                enterIdentity: "Syötä ajoneuvon tunnistetiedot",
                phone: "Puhelin",
                enterPhone: "Syötä puhelinnumero",
                email: "Sähköposti",
                files: "Tiedostot",
                enterEmail: "Syötä sähköpostiosoite",
                description: "Vian kuvaus",
                provideDescription: "Kuvaile vika",
                shopReturned: "Kauppa, johon tavara palautetaan/tulee palauttaa arviointia varten",
                chooseShop: "Valitse kauppa",
                chooseFiles: "Valitse tiedostot",
                remove: "Poista",
                totalSize: "Yhteiskoko"
            },
            validationMessages: {
                removalDateComparison: "Poistopäivän on oltava suurempi tai yhtä suuri kuin asennuspäivä",
                installDateComparison: "Asennuspäivä ei voi olla suurempi kuin poistopäivä",
                removedOdoComparison: "Poiston matkamittarin lukeman on oltava suurempi tai yhtä suuri kuin asennuksen matkamittarin lukema",
                installedOdoComparison: "Asennuksen matkamittarin lukema ei voi olla suurempi kuin poiston matkamittarin lukema",
                required: "Tämä kenttä on pakollinen",
                wholeNumbers: "Syötä kokonaisluku",
                email: "Syötä kelvollinen sähköpostiosoite",
                maxDate: "Annettu päivämääräarvo on tulevaisuudessa",
                minDate: "Syötä päivämäärä, joka on vuoden 2000.01.01 jälkeen",
                invalidDateFormat: "Virheellinen päivämäärämuoto",
                totalFileSize: "Yhdistetyn tiedostokoon on oltava enintään 20 MB"
            }
        },
        itemReturnList: {
            title: "Palautettujen tuotteiden luettelo",
            serverError: "Palautettujen tuotteiden luetteloa ei ole saatavilla (palvelinvirhe), yritä myöhemmin uudelleen!",
            tableTitles: {
                itemCardTitle: "Palautetun tuotteen numero",
                returnApplication: "Palauta sovellus",
                formSubmitted: "Lomake lähetetty",
                itemNumber: "Tuotenumero",
                user: "Käyttäjä",
                shopReturned: "Kauppa",
                status: "Tila",
            },
            statuses: {
                received: "Vastaanotettu",
                inReview: "Tarkastuksessa",
                atTheManufacturer: "Lähetetty valmistajalle",
                responseReceived: "Vastaus vastaanotettu",
                accepted: "Hyväksytty",
                rejected: "Hylätty",
            }
        },
        itemReturnInstructions: {
            mainPoints: {
                first: "TAVAROIDEN PALAUTUS ON SUORITETTAVA 10 PÄIVÄN KULUESSA OSTOSTA",
                second: "TAVAROIDEN ON OLTAVA EHJÄSSÄ JA KOSKEMATTOMASSA ALKUPERÄISPAAKKAUKSESSA",
                third: "TAKUUTA KOSKEVAT OSAT ON PUHDISTETTAVA VALMISTAJAN MERKINNÄT NÄKYVISSÄ",
                fourth: "ASIAKKAITTEN MUKAVUUDEN VUOKSI TÄYTÄ PALAUTUSLOMAKE SÄHKÖISESTI"
            },
            faq: {
                description: {
                    question: "Mitä suositellaan mainittavaksi kohdassa \"Vian kuvaus\"?",
                    answer: "Osaan liittyvä suorituskykyongelma tulisi kuvata:\n1. Miten se ilmeni ja milloin, mitä toimenpiteitä tehtiin jne.;\n2. Vian alue on merkittävä (merkitty) osaan;\n3. On suositeltavaa liittää video, joka vahvistaa kyseisen vian;\n4. Videonauhoitus on pakollinen öljyvuotojen ja muiden nestevuotojen, ilmavuotojen tapauksessa;\n5. Sähköpiirin osien - on oltava tietokonevirheluettelon tai sähköasentajan mittausresultaattien kanssa (voi olla videomuodossa);\n6. OE-osat: kaasupolkimet, modulaattorit (OE, Knor-Bremse, WABCO); sähkön tasausventtiilit (OE, Knor-Bremse, WABCO); ohjausyksiköt (OE, Knor-Bremse, WABCO); ohjausanturit (OE, Knor-Bremse, WABCO); virhekoodien tuloste on toimitettava yhdessä väitetyn viallisen osan kanssa."
                },
                cases: {
                    question: "Missä tapauksissa tuotetta ei palauteta?",
                    answer: "1. Jos alkuperäinen pakkaus on vaurioitunut tai saastunut tai sitä ei ole säilytetty;\n2. Sähköosia ei palauteta, jos sinetöity pakkaus on vaurioitunut;\n3. Korjaussarjat, jos niiden pakkaus on avattu;\n4. Erityisesti tilatut tuotteet eivät kuulu vaihtoon tai palautukseen."
                },
                processLength: {
                    question: "Kuinka kauan takuuvaatimuksen käsittely kestää?",
                    answer: "Mahdolliset takuutapaukset käsitellään ja lähetetään valmistajalle AUTO KADA - palveluedustajan toimesta viikon kuluessa.\nValmistaja vastaa takuun loppuosasta, mukaan lukien sen kesto."
                },
                conditions: {
                    question: "Missä kunnossa vanhat tapaukset hyväksytään?",
                    answer: "Vanhojen tapausten on oltava puhtaita ja öljyttömiä.\nSACHS-kytkinpalauteyleiset <link1>ehto</link1> ja <link2>tiivistelmä</link2>.\nBTS TURBO -turbiinien palautuskäytäntö löytyy <link3>täältä</link3>.\nEMITEC ADBLUE-kotelojen palautussäännöt löytyvät <link4>täältä</link4>.\nKatso ALBONAIR ADBLUE -kotelojen palautuskäytäntö <link5>täältä</link5>.\nECOFIT ADBLUE -kotelojen palautussäännöt löytyvät <link6>täältä</link6>.\nKatso jarrusatuloiden palautuskäytäntö <link7>täältä</link7>. WABCO-palautuskäytäntö löytyy <link8>täältä</link8>.\nKNORR-BREMSE-palautuskäytäntö löytyy <link9>täältä</link9>.\nREMA.GERMANY-palautuskäytäntö löytyy <link10>täältä</link10>."
                },
            },
        },
        itemReturnItem: {
            part: "Tuote",
            status: "Tila",
            submitDate: "Lomake lähetetty",
            docNumber: "Asiakirjanumero",
            email: "Sähköposti",
            installDate: "Tuote asennettu (päivämäärä)",
            removeDate: "Tuote poistettu (päivämäärä)",
            installOdo: "Asennettu tuote (matkamittari)",
            removeOdo: "Tuote poistettu (matkamittari)",
            phone: "Puhelin",
            purchaseDate: "Ostospäivä",
            registeredOffice: "Lakisääteinen osoite",
            shopReturned: "Kauppa",
            user: "Käyttäjä",
            vehicle: "Ajoneuvon tunnistetiedot",
            userInfo: "Käyttäjätiedot",
            description: "Vian kuvaus",
            attachments: "Liitteet",
            response: "Vastaus",
            error: "Palvelinvirhe. Yritä uudelleen myöhemmin!"
        },
    },
};
