import { useState, useEffect, memo } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchItemReturnList } from "../../../core/redux/asyncActions/global";
import { StyledCircularProgress } from "../../../components/CircularProgress";
import { Title, Wrapper } from "../../../components/SideListening/style";
import Box from "@material-ui/core/Box";
import {
    ProductListHeader,
    ProductListHeaderItem,
    ProductListItem,
    ProductSpec
} from "../../../components/Listing/style";
import StyledPagination from "../../../components/Pagination";
import { OrderList } from "../../Order/style";
import { ReturnItemCard } from "./ReturnItemCard";

export const ReturnsList = memo(() => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [returnList, setReturnList] = useState("")
    const [isLoading, setIsLoading] = useState("")

    const getStatusText = (statusCode) => {
        switch (statusCode) {
            case 0:
                return t("itemReturnList.statuses.received");
            case 1:
                return t("itemReturnList.statuses.inReview");
            case 2:
                return t("itemReturnList.statuses.atTheManufacturer");
            case 3:
                return t("itemReturnList.statuses.responseReceived");
            case 4:
                return t("itemReturnList.statuses.accepted");
            case 5:
                return t("itemReturnList.statuses.rejected");
        }
    };

    const setPageNumber = (pageNumber) => {
        setPage(pageNumber);
    };

    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const data = await dispatch(fetchItemReturnList(page));
                if (data.type === "FETCH_ITEM_RETURN_LIST_FAILURE") {
                    setPage(1);
                    setIsLoading(false);
                } else {
                    setTotalPages(data?.payload?.count / 25);
                    setReturnList(data.payload.results);
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [page, dispatch]);

    return (
        <Wrapper>
            <Title>{t("itemReturnList.title").toUpperCase()}</Title>
            <OrderList>
                <ProductListHeader>
                    <ProductListHeaderItem type="returnList">
                        {t("itemReturnList.tableTitles.returnApplication").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem type="returnList">
                        {t("itemReturnList.tableTitles.formSubmitted").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem type="returnList">
                        {t("itemReturnList.tableTitles.itemNumber").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem type="returnList">
                        {t("itemReturnList.tableTitles.user").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem type="returnList">
                        {t("itemReturnList.tableTitles.shopReturned").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem type="returnList">
                        {t("itemReturnList.tableTitles.status").toUpperCase()}*
                    </ProductListHeaderItem>
                </ProductListHeader>
                {
                    isLoading ? (
                        <Box display="flex" alignItems="center" justifyContent="center" margin="40px">
                            <StyledCircularProgress disableShrink/>
                        </Box>
                    ) : Boolean(returnList) ? (
                        <>
                            {returnList.map((item) => (
                                !isPhoneScreen ? (
                                    <Link to={`/item-returns-item/${item.id}`} style={{"text-decoration": "none"}}>
                                        <ProductListItem>
                                            <ProductSpec type="returnList">{item.return_application_number}</ProductSpec>
                                            <ProductSpec type="returnList">{item.form_submitted_date}</ProductSpec>
                                            <ProductSpec type="returnList">{item.item_number}</ProductSpec>
                                            <ProductSpec type="returnList">{item.user}</ProductSpec>
                                            <ProductSpec type="returnList">{item.shop_returned}</ProductSpec>
                                            <ProductSpec type="returnList">{getStatusText(item.status)}</ProductSpec>
                                        </ProductListItem>
                                    </Link>
                                ) : (
                                    <div style={{ marginBottom: "10px" }}>
                                        <ReturnItemCard item={item} status={getStatusText(item.status)}/>
                                    </div>
                                )
                            ))}
                            {!isLoading && (
                                <>
                                    <Box style={{ display: "flex", justifyContent: "center" }}>
                                        <StyledPagination
                                            count={totalPages}
                                            pageNumber={page}
                                            setPageNumber={setPageNumber}
                                        />
                                    </Box>
                                    <Box style={{ display: "flex", justifyContent: "left", fontSize: "12px", marginTop: "30px" }}>
                                        <div>
                                            *{t("itemReturnList.statuses.received")} -&gt;&nbsp;
                                            {t("itemReturnList.statuses.inReview")} -&gt;&nbsp;
                                            {t("itemReturnList.statuses.atTheManufacturer")} -&gt;&nbsp;
                                            {t("itemReturnList.statuses.responseReceived")} -&gt;&nbsp;
                                            {t("itemReturnList.statuses.accepted")}/{t("itemReturnList.statuses.rejected")}
                                        </div>
                                    </Box>
                                </>
                            )}
                        </>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                height: "100px",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                fontWeight: "500",
                                fontSize: "18px"
                            }}
                        >
                            {t("itemReturnList.serverError")}
                        </div>
                    )
                }
            </OrderList>
        </Wrapper>
    );
});