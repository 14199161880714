import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
`;

export default function Wrapper({ children, column }) {
  return <Container column={column}>{children}</Container>;
}
