import styled, { css } from "styled-components";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { breakpoints } from "../../../core/utils/style";
import FormControl from "@material-ui/core/FormControl";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";


export const FormStyledDatePicker = styled(KeyboardDatePicker)`
    .MuiInputBase-root {
        width: 100%
        background: #ffffff;
        border: 1px solid #37474f;
        box-sizing: border-box;
        border-radius: 2px;
        height: 48px;
        color: var(--main-color);
    }
    .MuiInputBase-root.MuiInput-root.Mui-focused {
        border: 1px solid #f28a22;
    }
    .MuiInputBase-input {
        height: 100%;
        padding: 0 14px;
        font-size: 16px;
    }
    .MuiSvgIcon-root {
        height: 20px;
        width: 20px;
    }

    .MuiFormHelperText-root.Mui-error {
        color: #cc0000;
        font-size: 11px;
        margin-top: 6px;
        height: 13px;
        display: flex;
        align-items: center;
    }
`;

export const ReturnFormSelectControl = styled(FormControl)`
    border: 1px solid #37474f !important;
    border-radius: 2px;
    box-sizing: border-box;
    width: 100%;

    fieldset {
        border: 0;
    }

    .MuiInputBase-root {
        width: 100%;
    }

    .MuiSelect-root {
        color: var(--main-color);
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink,
    .PrivateNotchedOutline-legendNotched-4 {
        display: none;
    }

    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
        color: #37474f;
        font-size: 16px;
        line-height: 24px;
    }

    .MuiSelect-outlined.MuiSelect-outlined {
        font-size: 16px;
        height: 25px;
        display: flex;
        align-items: center;
    }
`;

export const TextArea = styled.textarea`
    background: #ffffff;

    border: 1px solid #37474f;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 14px;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 24px;
    height: 150px;
    /* identical to box height, or 171% */

    display: flex;
    align-items: center;
    letter-spacing: 0.01px;

    color: var(--main-color);
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    max-height: 500px;
    min-height: 80px;

    &::placeholder {
        color: #bcbcbc;
        font-size: 14px;
    }
`;

export const ErrorBox = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    height: 20px;
    width: 100%;
    color: #cc0000;
    font-weight: 500;
    margin-bottom: ${props => (props.topMessage ? '10px' : '20px')};

    @media (min-width: ${breakpoints.md}) {
        margin-top: ${props => (props.topMessage ? '0' : '10px')};
        margin-bottom: 10px;
    }
`;

export const ProgressBlock = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    @media (min-width: ${breakpoints.md}) {
        margin-top: 5px;
        margin-bottom: 0;
    }
`;

export const LinkButton = styled.button`
    cursor: pointer;
    height: 100%;
    align-items: center;
    background: #f28a22;
    border-radius: 2px;
    width: 100%;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;

    @media (min-width: ${breakpoints.md}) {
        &:hover {
            color: rgba(255, 255, 255, 0.8);
        }
    }
`;

export const LinkContainer = styled.div`
    height: 36px;
    width: 100%;
    margin: 20px 0 20px 0;

    @media (min-width: ${breakpoints.md}) {
        width: 45%;
        height: 48px;
        max-width: 600px;
        margin: 30px auto 0;
    }
`;

export const InstructionsContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    @media (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.xl}){
        width: 80%;
    }
    @media (min-width: ${breakpoints.xl}) {
        width: 65%;
    }
`;

export const MainPoints = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 100%;

    @media (min-width: ${breakpoints.lg}) {
        flex-wrap: wrap;
        flex-direction: row;
    }
`;

export const Point = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    height: 120px;
    width: 100%;
    background: rgba(224, 224, 224, 0.5);
    margin: 10px auto;
    border-radius: 2px;
    font-size: 17px;
    padding-right: 15px;
    color: #37474f;

    @media (min-width: ${breakpoints.lg}) {
        width: 47%;
        height: 120px;
    }

    @media (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
        width: 100%;
        height: 100px;  
    }

    @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md}) {
        width: 100%;
        height: 110px;  
    }

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        padding-right: 0;
        font-size: 16px;
        max-height: 200px;
        height: 100%;
    }
`;

export const Number = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 30px;
    height: 100%;
    background: #f28a22;
    border-radius: 2px;
    margin-right: 15px;

    @media (min-width: ${breakpoints.lg}) {
        width: 120px;
        min-width: 120px;
    }

    @media (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}){
        width: 100px;
        min-width: 100px;
    }

    @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md}){
        width: 110px;
        min-width: 110px;  
    }

    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 25px;
        font-size: 24px;
        height: 50px;
        min-height: 50px;
    }
`;

export const Text = styled.div`
    @media (max-width: ${breakpoints.sm}) {
        margin: 0 20px 25px 20px;
    }
`;

export const Questions = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 30px;
    justify-content: center;
    padding: 0 6%;

    @media (max-width: ${breakpoints.md}) {
        padding: 0;
    }
`;

export const Container = styled.div`
    color: #37474f;
    border-top: 1px solid rgb(224, 224, 224);
    border-right: 1px solid rgb(224, 224, 224);
    border-left: 1px solid rgb(224, 224, 224);
    position: relative;
    transition: all 0.3s ease;
    &:hover {
        background-color: rgba(224, 224, 224, 0.5);
    }

    ${({ bottom }) =>
        bottom &&
        css`
            border-bottom: 1px solid rgb(224, 224, 224);
            border-bottom-right-radius: 2px;
            border-bottom-left-radius: 2px;
    `}

    ${({ top }) =>
        top &&
        css`
            border-top-right-radius: 2px;
            border-top-left-radius: 2px;
    `}
`;

export const Arrow = styled.span`
    position: absolute;
    right: 10px;
    top: 34px;
    cursor: pointer;
    transition: transform 0.3s ease;
`;

export const StyledArrowBackIos = styled(ArrowBackIos)`
    color: #f28a22;
`;

export const Question = styled.div`
    cursor: pointer;
    padding: 20px;
    font-size: 17px;
    font-weight: 450;

    @media (max-width: ${breakpoints.md}){
        padding-right: 40px;  
    }
`;

export const Answer = styled.div`
    overflow: hidden;
    padding: 0 20px 0 20px;
    white-space: pre-line;
    transition: all 0.3s ease;
    line-height: 25px;
    margin-bottom: ${props => (props.open ? "20px" : "0")};

    ${({ top }) =>
        top &&
        css`
            max-height: ${props => (props.open ? "220px" : "0")};
            @media (min-width: ${breakpoints.lg}) and (max-width: ${breakpoints.xl}){
                max-height: ${props => (props.open ? "250px" : "0")};
            }
            @media (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}){
                max-height: ${props => (props.open ? "280px" : "0")};
            }
            @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md}){
                max-height: ${props => (props.open ? "320px" : "0")};
            }
            @media (min-width: 500px) and (max-width: ${breakpoints.sm}){
                max-height: ${props => (props.open ? "450px" : "0")};
            }
            @media (min-width: ${breakpoints.xs}) and (max-width: 500px){
                max-height: ${props => (props.open ? "550px" : "0")};
            }
            @media (max-width: ${breakpoints.xs}){
                max-height: ${props => (props.open ? "650px" : "0")};
            }
    `}

    ${({ second }) =>
        second &&
        css`
            max-height: ${props => (props.open ? "100px" : "0")};
            @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md}){
                max-height: ${props => (props.open ? "120px" : "0")};
            }
            @media (max-width: ${breakpoints.sm}){
                max-height: ${props => (props.open ? "200px" : "0")};
            }
    `}

    ${({ third }) =>
        third &&
        css`
            max-height: ${props => (props.open ? "50px" : "0")};
            @media (min-width: ${breakpoints.lg}) and (max-width: ${breakpoints.xl}){
                max-height: ${props => (props.open ? "80px" : "0")};
            }
            @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.lg}){
                max-height: ${props => (props.open ? "100px" : "0")};
            }
            @media (max-width: ${breakpoints.sm}){
                max-height: ${props => (props.open ? "180px" : "0")};
            }
    `}

    ${({ bottom }) =>
        bottom &&
        css`
            max-height: ${props => (props.open ? "230px" : "0")};
            @media (min-width: 510px) and (max-width: 640px){
                max-height: ${props => (props.open ? "250px" : "0")};
            }
            @media (min-width: 450px) and (max-width: 510px){
                max-height: ${props => (props.open ? "350px" : "0")};
            }
            @media (max-width: 450px){
                max-height: ${props => (props.open ? "400px" : "0")};
            }
    `}
`;

export const StyledLink = styled.a`
    color: #f28a22;
    text-decoration: none;
`;