import React from "react";
import Header from "../../components/Header";
import Wrapper from "../../components/Wrapper";
import { ReturnsList } from "./components/ReturnsList";

export const ReturnItemList = () => {
    return (
        <div>
            <Header />
            <Wrapper>
                <ReturnsList />
            </Wrapper>
        </div>
    );
};