import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import {
    ItemCardWrapper,
    ItemSpec,
    TitleWrapper 
} from "../../../components/Listing/components/ItemCard/style";
import { useTranslation } from "react-i18next";


export const ReturnItemCard = ({ item, status }) => {
    const { t } = useTranslation()
    const history = useHistory();

    const handleItemPopupClick = (e, itemId) => {
        e.preventDefault();
        history.push(`/item-returns-item/${itemId}`);
    };

    return (
        <ItemCardWrapper>
            <Box flex="1" display="flex" flexDirection="column">
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    marginBottom="12px"
                >
                    <TitleWrapper onClick={(e) => handleItemPopupClick(e, item.id)}>
                        {`${t("itemReturnList.tableTitles.itemCardTitle")}: ${item.item_number}`}
                    </TitleWrapper>
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <ItemSpec>
                            {`${t("itemReturnList.tableTitles.formSubmitted")}: ${item.form_submitted_date}`}
                        </ItemSpec>
                    </Grid>
                    <Grid item xs={6}>
                        <ItemSpec>
                            {`${t("itemReturnList.tableTitles.vehicle")}: ${item.vehicle_identification}`}
                        </ItemSpec>
                    </Grid>
                    <Grid item xs={6}>
                        <ItemSpec>
                            {`${t("itemReturnList.tableTitles.user")}: ${item.user}`}
                        </ItemSpec>
                    </Grid>
                    <Grid item xs={6}>
                        <ItemSpec>
                            {`${t("itemReturnList.tableTitles.shopReturned")}: ${item.shop_returned}`}
                        </ItemSpec>
                    </Grid>
                    <Grid item xs={6}>
                        <ItemSpec>
                            {`${t("itemReturnList.tableTitles.status")}*: ${status}`}
                        </ItemSpec>
                    </Grid>
                </Grid>
            </Box>
        </ItemCardWrapper>
    );
};
